<template>
<section id="sub">
    <div class="sub-vis main-banner"><span>이벤트</span></div>
        
    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/promotion/event">이벤트</router-link>
    </div>

    <div id="content">
        <div class="lnbWrap">
            <ul id="lnb">
                <li v-for="(item,index) in category" :key="index" :class="{'active': $route.matched[1].regex.test(item.link)}">
                    <router-link :to="item.link" v-if="item.depth.length != 0" @click="open=item.no">{{item.title}}</router-link>
                    <router-link :to="item.link" v-else>{{item.title}}</router-link>
                    <ul class="depth02" v-if="open===item.no&&item.depth.length != 0">
                        <li v-for="(item2,index2) in item.depth" :key="index2">
                            <router-link :to="item2.link">{{item2.title}}</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>


        <div class="prdt-list2 mt0">
            <div class="space-box relative">
                <div class="space-page-body home-page relative">
                    <div class="space-archive-section box-100 relative homepage-block" style="transform: none;">
                        <div class="space-archive-section-home-ins space-page-wrapper relative" style="transform: none;">

                            <div id="sb-search">
                                <span class="total">전체 <strong>{{list.length}}</strong>건</span>
                            </div>
                            <div id="sb-list">
                                <table class="list_tbl">
                                    <colgroup>
                                        <col style="width: auto;">
                                        <col style="width: 250px;">
                                        <col style="width: 160px;">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th class="sbj">제목</th>
                                            <th class="ans">이벤트 기간</th>
                                            <th class="date">등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in pageOfItems" :key="index">
                                            <td class="sbj" >
                                                <router-link :to="'/promotion/event/event/article/'+item.no">
                                                {{item.title}}
                                                </router-link>
                                            </td>
                                            <td class="event_range">
                                                {{item.start_date}} ~ {{item.end_date}}
                                            </td>
                                            <td class="create_date">
                                                {{item.create_date}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                
                                <nav aria-label="Page navigation example" style="text-align: center;" v-if="list.length > 0">
                                    <jw-pagination :items="list" :pageSize="10" :maxPages="5" @changePage="onChangePage" :labels="customLabels"></jw-pagination>
                                </nav>
                            </div>

                            <div id="sb-footer">
                                <div class="right">
                                    <router-link to="/contacts/qna/writer" class="sb-btn type1">등록</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import paginate from 'jw-paginate';

export default {
    data(){
        return{
            open: 1,
            category: [
                {
                    no: 0,
                    title: '이벤트',
                    link: '/promotion/event/event',
                    depth: [
                        {
                            no: 0,
                            title: '진행중인 이벤트',
                            link: '/promotion/event/event'
                        },
                        {
                            no: 0,
                            title: '종료된 이벤트',
                            link: '/promotion/event/finish'
                        },
                    ]
                },
                // {
                //     no: 1,
                //     title: '당첨자 발표',
                //     link: '/promotion/winner',
                //     depth: []
                // },
                // {
                //     no: 2,
                //     title: '제휴혜택',
                //     link: '/promotion/benefit',
                //     depth: []
                // }
            ],
            list: [
                {
                    no: 0,
                    title: '이벤트 제목',
                    start_date: '2022-09-17',
                    end_date: '2022-10-30',
                    create_date: '2022-11-01'
                },
                {
                    no: 0,
                    title: '이벤트 제목',
                    start_date: '2022-09-17',
                    end_date: '2022-10-30',
                    create_date: '2022-11-01'
                },
                {
                    no: 0,
                    title: '이벤트 제목',
                    start_date: '2022-09-17',
                    end_date: '2022-10-30',
                    create_date: '2022-11-01'
                },
                {
                    no: 0,
                    title: '이벤트 제목',
                    start_date: '2022-09-17',
                    end_date: '2022-10-30',
                    create_date: '2022-11-01'
                },
                {
                    no: 0,
                    title: '이벤트 제목',
                    start_date: '2022-09-17',
                    end_date: '2022-10-30',
                    create_date: '2022-11-01'
                },
                {
                    no: 0,
                    title: '이벤트 제목',
                    start_date: '2022-09-17',
                    end_date: '2022-10-30',
                    create_date: '2022-11-01'
                },
            ],

            
            pageOfItems: [],
            customLabels: {}
        }
    },
    mounted() {
        console.log(this.$route);
    },
    methods: {     
        back_history() {
            this.$router.go(-1);
        },
        
        setPage(page) {
            const { items, pageSize, maxPages } = this;

            // get new pager object for specified page
            const pager = paginate(items.length, page, pageSize, maxPages);

            // get new page of items from items array
            const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

            // update pager
            this.pager = pager;

            // emit change page event to parent component
            this.$emit('changePage', pageOfItems);
        },
        onChangePage(pageOfItems) {
            // update page of items
            this.pageOfItems = pageOfItems;
        },
    },
}
</script>
<style scoped>
    #sb-list .list_tbl tr > .event_range, 
    #sb-list .list_tbl tr > .create_date{
        font-size: 0.8rem;
    }
    #sb-list .list_tbl tr > *{
        border: none;
        border-bottom: 1px solid #ddd;
    }
    #sb-list .list_tbl tbody tr > *{
        padding: 30px 12px;
    }

    @media screen and (max-width: 768px) {
        .list_tbl, .list_tbl tbody{
            display: block;
        }
        .list_tbl tr{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
        }
        #sb-list .list_tbl thead{
            display: none;
        }
        #sb-list .list_tbl tbody tr td.sbj{
            width: 100%;
            border: none;
            padding: 20px 12px 0px;
        }
        #sb-list .list_tbl tbody tr:not(:first-child) td.sbj{
            border-top: 1px solid #ddd;
        }
        #sb-list .list_tbl tbody tr>.event_range{
            padding-top: 8px;
            border: none;
            padding-bottom: 20px;
        }
        #sb-list .list_tbl tbody tr>.create_date{
            padding-top: 8px;
            border: none;
            padding-bottom: 20px;
            margin-left: auto;
        }
    }
</style>