<template>
<section id="sub">
    <div class="sub-vis main-banner"><span>이벤트</span></div>
        
    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/promotion/event">이벤트</router-link>
    </div>

    <div id="content">
        <div class="lnbWrap">
            <ul id="lnb">
                <li v-for="(item,index) in category" :key="index" :class="{'active': $route.matched[1].regex.test(item.link)}">
                    <router-link :to="item.link" v-if="item.depth.length != 0" @click="open=item.no">{{item.title}}</router-link>
                    <router-link :to="item.link" v-else>{{item.title}}</router-link>
                    <ul class="depth02" v-if="open===item.no&&item.depth.length != 0">
                        <li v-for="(item2,index2) in item.depth" :key="index2">
                            <router-link :to="item2.link">{{item2.title}}</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="prdt-list2 mt0">
            <div>
                <ul class="banner_card_list">
                    <li class="card_box" v-for="(item,index) in list" :key="index">
                        <router-link :to="`/promotion/event/event/article/${item.no}`">
                            <div class="img_box">
                                <div class="bg_img" :style="`background-image: url( ${item.img})`"></div>
                            </div>
                            <div class="txt_box">
                                <div class="title">{{item.title}}</div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="pagination_group">
                <button 
                disabled="disabled"
                class="pagination_arw arw_perv type_first">
                <i class="fal fa-chevron-double-left"></i>
                </button>
                <button 
                disabled="disabled"
                class="pagination_arw arw_perv">
                <i class="fal fa-chevron-left"></i>
                </button> 
                <div class="pager_nums">
                    <button disabled="disabled" class="is_active">
                    1
                    </button>
                </div>
                <button 
                disabled="disabled"
                class="pagination_arw arw_next">
                <i class="fal fa-chevron-right"></i>
                </button>
                <button 
                disabled="disabled" 
                class="pagination_arw arw_next type_last">
                <i class="fal fa-chevron-double-right"></i>
                </button>
            </div>
        </div>
    </div>
</section>
</template>

<script>

export default {
    data(){
        return{
            open: 2,
            category: [
                {
                    no: 0,
                    title: '이벤트',
                    link: '/promotion/event/event',
                    depth: [
                        {
                            no: 0,
                            title: '진행중인 이벤트',
                            link: '/promotion/event/event'
                        },
                        {
                            no: 0,
                            title: '종료된 이벤트',
                            link: '/promotion/event/finish'
                        },
                    ]
                },
                {
                    no: 1,
                    title: '당첨자 발표',
                    link: '/promotion/winner',
                    depth: []
                },
                {
                    no: 2,
                    title: '제휴혜택',
                    link: '/promotion/benefit',
                    depth: []
                }
            ],
            list: [
                {
                    no: 0,
                    title: '제휴사 이름',
                    img: 'https://octapi.lxzin.com/eventMst/imgBannerPc/202208/25/61abb53a-896c-4091-b8a0-8584a0743ae8.jpg/지인몰_이벤트배너_상담신청.jpg'
                },
                {
                    no: 0,
                    title: '제휴사 이름',
                    img: 'https://octapi.lxzin.com/eventMst/imgBannerPc/202208/25/61abb53a-896c-4091-b8a0-8584a0743ae8.jpg/지인몰_이벤트배너_상담신청.jpg'
                },
                {
                    no: 0,
                    title: '제휴사 이름',
                    img: 'https://octapi.lxzin.com/eventMst/imgBannerPc/202208/25/61abb53a-896c-4091-b8a0-8584a0743ae8.jpg/지인몰_이벤트배너_상담신청.jpg'
                },
                {
                    no: 0,
                    title: '제휴사 이름',
                    img: 'https://octapi.lxzin.com/eventMst/imgBannerPc/202208/25/61abb53a-896c-4091-b8a0-8584a0743ae8.jpg/지인몰_이벤트배너_상담신청.jpg'
                },
                {
                    no: 0,
                    title: '제휴사 이름',
                    img: 'https://octapi.lxzin.com/eventMst/imgBannerPc/202208/25/61abb53a-896c-4091-b8a0-8584a0743ae8.jpg/지인몰_이벤트배너_상담신청.jpg'
                },
                {
                    no: 0,
                    title: '제휴사 이름',
                    img: 'https://octapi.lxzin.com/eventMst/imgBannerPc/202208/25/61abb53a-896c-4091-b8a0-8584a0743ae8.jpg/지인몰_이벤트배너_상담신청.jpg'
                },
            ]
        }
    },
    mounted() {
        console.log(this.$route);
    },
    methods: {     
        back_history() {
            this.$router.go(-1);
        }
    },
}
</script>