<template>
<section id="sub">
    <div class="sub-vis main-banner"><span>이벤트</span></div>
        
    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/promotion/event">이벤트</router-link>
    </div>

    <div id="content">
        <div class="lnbWrap">
            <ul id="lnb">
                <li v-for="(item,index) in category" :key="index" :class="{'active': $route.matched[1].regex.test(item.link)}">
                    <router-link :to="item.link" @click="open=item.no">{{item.title}}</router-link>
                </li>
            </ul>
        </div>
        <!-- <div class="lnbWrap">
            <ul id="lnb">
                <li v-for="(item,index) in category" :key="index" :class="{'active': $route.matched[1].regex.test(item.link)}">
                    <router-link :to="item.link" v-if="item.depth.length != 0" @click="open=item.no">{{item.title}}</router-link>
                    <router-link :to="item.link" v-else>{{item.title}}</router-link>
                    <ul class="depth02" v-if="open===item.no&&item.depth.length != 0">
                        <li v-for="(item2,index2) in item.depth" :key="index2">
                            <router-link :to="item2.link">{{item2.title}}</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div> -->

        <div class="prdt-list2 mt0">
            <div>
                <ul class="banner_card_list">
                    <li class="card_box" v-for="(item,index) in list" :key="index">
                        <router-link :to="`/promotion/event/event/article/${item.no}`">
                            <!-- <div class="img_box">
                                <div class="bg_img" :style="`background-image: url( ${item.img})`"></div>
                            </div> -->
                            <img class="img_box event_banner" :src="item.img">
                            <div class="txt_box">
                                <div class="title">
                                    <p style="float:left; margin-right: 5px;" v-if="item.end == 0">[진행중]</p>
                                    <p style="float:left; margin-right: 5px;" v-else>[종료]</p>
                                    {{item.title}}
                                </div>
                                <div class="date">
                                    {{item.start_date}} ~ {{item.end_date}}
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="pagination_group">
                <button 
                disabled="disabled"
                class="pagination_arw arw_perv type_first">
                <i class="fal fa-chevron-double-left"></i>
                </button>
                <button 
                disabled="disabled"
                class="pagination_arw arw_perv">
                <i class="fal fa-chevron-left"></i>
                </button> 
                <div class="pager_nums">
                    <button disabled="disabled" class="is_active">
                    1
                    </button>
                </div>
                <button 
                disabled="disabled"
                class="pagination_arw arw_next">
                <i class="fal fa-chevron-right"></i>
                </button>
                <button 
                disabled="disabled" 
                class="pagination_arw arw_next type_last">
                <i class="fal fa-chevron-double-right"></i>
                </button>
            </div>
        </div>
    </div>
</section>
</template>

<script>

export default {
    data(){
        return{
            open: 0,
            category: [
                {
                    no: 0,
                    title: '이벤트',
                    link: '/promotion/event/event',
                    // depth: [
                    //     {
                    //         no: 0,
                    //         title: '진행중인 이벤트',
                    //         link: '/promotion/event/event'
                    //     },
                    //     {
                    //         no: 0,
                    //         title: '종료된 이벤트',
                    //         link: '/promotion/event/finish'
                    //     },
                    // ]
                },
                // {
                //     no: 1,
                //     title: '당첨자 발표',
                //     link: '/promotion/winner',
                //     depth: []
                // },
                // {
                //     no: 2,
                //     title: '제휴혜택',
                //     link: '/promotion/benefit',
                //     depth: []
                // }
            ],
            list: [
                {
                    no: 0,
                    title: '현대L&C 미리썸머 리모델링 이벤트',
                    img: require('../../assets/images/event/e1.jpg'),
                    start_date: '2022-05-13',
                    end_date: '2022-6-30',
                    end: 1
                },
                {
                    no: 1,
                    title: '맵시나는 나만의 주방 만들기 이벤트',
                    img: require('../../assets/images/event/e2.jpg'),
                    start_date: '2022-04-08',
                    end_date: '2022-04-30',
                    end: 1
                },
                {
                    no: 2,
                    title: '현대L&C 얼리버드 EVENT',
                    img: require('../../assets/images/event/e3.jpg'),
                    start_date: '2022-03-23',
                    end_date: '2022-04-03',
                    end: 1
                },
                {
                    no: 3,
                    title: '현대L&C 봄맞이 인테리어 이벤트',
                    img: require('../../assets/images/event/e4.png'),
                    start_date: '2022-03-04',
                    end_date: '2022-04-30',
                    end: 1
                },
            ]
        }
    },
    mounted() {
        console.log(this.$route);
        this.open = 0;
    },
    methods: {     
        back_history() {
            this.$router.go(-1);
        }
    },
}
</script>
<style scoped>
.main-banner {
    background-image: url('../../assets/images/banner/sub_img.png');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.fulldiv {
    background-image: url('../../assets/images/banner/main_image_3.jpg');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}
.event_banner{
    width: 100%;
    background-color: #f7f7f7;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: all 0.5s;
}
</style>