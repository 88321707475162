<template>
    <section id="sub">
        <div class="sub-vis main-banner"><span>이벤트</span></div>

        <div class="container breadcrumbs">
            <router-link to="/"><i class="fal fa-home"></i></router-link> > 
            <router-link to="/promotion/event/event">이벤트</router-link> > 
            <span>상세보기</span>
        </div>

        <div id="content">
            <div class="w-100">
                <dl class="prdt-view">
                    <dd>
                        <div class="head">
                            <h4>{{detail.title}}</h4>
                            <small class="me-4">이벤트 기간 : {{detail.start_date}} ~ {{detail.end_date}}</small>
                            <small class="create_time">등록일 : {{detail.create_time}}</small>
                        </div>

                        <div class="reference_box" v-if="detail.reference_link != ''">
                            <router-link :to="detail.reference_link">
                                관련 이벤트
                                <span>{{detail.reference_title}}</span>
                            </router-link>
                        </div>
                        
                        <div class="center_content">
                            <img :src="detail.content_img" alt="">
                        </div>
                    </dd>
                </dl>
                <div id="sb-footer">
                    <div class="center">
                        <router-link :to="`/promotion/event/event`" class="sb-btn type1">목록보기</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            AWSURL: this.$store.state.AWS.URL,
            index: this.$route.params.no,
            passwordTF:false,
            password: '',

            list: [
                {
                    no: 0,
                    title: '현대L&C 미리썸머 리모델링 이벤트',
                    img: require('../../assets/images/event/e1.jpg'),
                    content_img: require('../../assets/images/event/event1.jpg'),
                    start_date: '2022-05-13',
                    end_date: '2022-6-30',
                    end: 1
                },
                {
                    no: 1,
                    title: '맵시나는 나만의 주방 만들기 이벤트',
                    img: require('../../assets/images/event/e2.jpg'),
                    content_img: require('../../assets/images/event/event2.jpg'),
                    start_date: '2022-04-08',
                    end_date: '2022-04-30',
                    end: 1
                },
                {
                    no: 2,
                    title: '현대L&C 얼리버드 EVENT',
                    img: require('../../assets/images/event/e3.jpg'),
                    content_img: require('../../assets/images/event/event3.jpg'),
                    start_date: '2022-03-23',
                    end_date: '2022-04-03',
                    end: 1
                },
                {
                    no: 3,
                    title: '현대L&C 봄맞이 인테리어 이벤트',
                    img: require('../../assets/images/event/e4.png'),
                    content_img: require('../../assets/images/event/event4.png'),
                    start_date: '2022-03-04',
                    end_date: '2022-04-30',
                    end: 1
                },
            ],

            detail: {
                title:'이벤트 관련 제목',
                start_date: '2022-09-22',
                end_date: '2022-09-22',
                create_time: '2022-09-22',
                content_img: 'https://octapi.lxzin.com/imageResource/file/202208/24/1e15337e-e99c-4780-be8a-fe5dae829d48.jpg',
                reference_link : '/promotion/event/event/article/1',
                reference_title : '8월 신규가입 이벤트'
            },

        }
    },
    mounted() {
        this.detailIMG();
    },
    methods: {
        detailIMG(){
            const index = this.index;
            const detail = this.list[index];
            this.detail = detail;
        }
    },
}
</script>

<style scoped>
.head{
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px;
}
#sub .head h4{
    font-size: 1.4em;
    margin-left: 0;
    margin-bottom: 12px;
}
#sub .head small{
    color: #aaa;
}


.center_content{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.prdt-view{
    border-bottom: 1px solid #ddd;
    padding-bottom: 50px;
}
.me-4{
    margin-right: 30px;
}
.reference_box{
    height: 70px;
    background: #f7f2f2;
    line-height: 70px;;
}
.reference_box a, .reference_box span{
    font-size: 1rem;
    color: #b93d40;
    margin-left: 20px;
}

@media screen and (max-width: 768px) {
    .head .create_time{
        display: block;
    }
}
</style>